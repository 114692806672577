<template>
  <screen_width_cont>
    <div class="cont_300_basico ancho_acotado">
      <div class="header_top">
        <headerCerrar @cancelar="cerrarModal" colorIcon="gris" />
      </div>

      <!-- título principal -->
      <div class="autenticaTitle title">
        {{ t("app.autentificacionRecoverPass.tit") }}
      </div>

      <!-- texto -->
      <div class="autenticaText">
        <span>{{ t("app.autentificacionRecoverPass.hola") }}&nbsp;</span>
        <span>{{ `${remitente.nombre1} ${remitente.nombre2}` }},&nbsp;</span>
        <span>{{ t("app.autentificacionRecoverPass.seguridad") }}&nbsp;</span>
        <span class="autenticaTextBold">{{ correo_parcial }}</span>
      </div>

      <!-- label código -->
      <div class="autenticaCodigo">
        {{ t("app.autentificacionRecoverPass.codigo") }}
      </div>

      <!-- input del código estilo mach -->
      <inputCodigoAutenticacion
        @revisarCodigoComp="revisarCodigoLocal"
        @tieneError="tieneErrorLocal"
        :liberar_campos="!procesando_codigo"
      />
      <div v-if="tieneError" class="errorCodigoLabel">
        {{ t("app.autentificacionRecoverPass.errorInvalido") }}
      </div>

      <div class="autenticaRPResendedCod">
        <!-- <span v-show="codigoResended">{{ t("app.autentificacionRecoverPass.reSendCod") }}</span>   -->
      </div>

      <!-- volver a generar código -->
      <div class="autenticaRPResend">
        <span @click="reSendCodigo" class="clickable">{{
          t("app.autentificacionRecoverPass.regenerarCodigo")
        }}</span>
      </div>

      <div class="autenticaRPEtapas">
        <pasosGiro paso="2" totales="3" />
      </div>
    </div>
  </screen_width_cont>
</template>


<script>
import { alertController, modalController } from "@ionic/vue";
import headerCerrar from "../components/generales/headerCerrar.vue";
import { useI18n } from "vue-i18n";
import { mapActions, mapMutations, mapState } from "vuex";
import inputCodigoAutenticacion from "../components/generales/inputCodigoAuteticacion.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import estas_seguro from "./modales/estas_seguro.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    headerCerrar,
    inputCodigoAutenticacion,
    pasosGiro,
    screen_width_cont,
  },
  data() {
    return {
      // codigo1: "",
      // codigo2: "",
      // codigo3: "",
      // codigo4: "",
      tieneError: false,
      title: "",
      subTitle: "",
      buttons: [],
      codigoResended: false,
      procesando_codigo: false,
      enVista: false,
      error_respForgotPass2:"",
    };
  },
  computed: {
    ...mapState(["remitente", "respForgotPass2", "widthScreen"]),

    correo_parcial() {
      let aux = "";
      if (this.remitente && this.remitente.correo) {
        let aux2 = this.remitente.correo.split("@")[1];
        aux = `${this.remitente.correo.substring(0, 3)}xxx@${aux2}`;
      }
      return aux;
    },
  },
  methods: {
    ...mapActions([
      "verificar_codigo_recover_pass",
      "re_send_cod_recover_pass",
    ]),
    ...mapMutations([
      "limpiarRemitente",
      "updateBloquearNavegacion",
      "setshowSpinner",
    ]),

    cerrarModal() {
      this.callAbandonar();

      // this.limpiarRemitente({
      //   ultimas_transacciones: [],
      // });
      // this.$router.push({ name: "logIn", replace: true });
    },
    revisarCodigoLocal(codigo) {
      console.log(codigo);
      this.procesando_codigo = true;
      this.setshowSpinner(true);
      this.verificar_codigo_recover_pass(codigo);
      //check_codigo_autentificacion
    },

    async reSendCodigo() {
      // console.log("reSendCodigo()");
      this.codigoResended = true;

      this.re_send_cod_recover_pass();

      let alert = await alertController.create({
        // header: this.title,
        subHeader: "Te hemos enviado un nuevo código",
        backdropDismiss: false,
        buttons: [
          {
            text: `Ok`,
            role: "cancel",
            cssClass: "customBtnClassAutentica",
          },
        ],
      });
      await alert.present();

      // this.openToast("Te hemos enviado un nuevo código");
      // this.subTitle = "Te hemos enviado un nuevo código";
      // this.buttons = ["Ok"];
      // this.presentAlert();
    },

    async presentAlert() {
      let alert = await alertController.create({
        header: "Error",
        subHeader: this.error_respForgotPass2,
        backdropDismiss: false,
        buttons: [`Ok`],
      });
      await alert.present();
    },
    tieneErrorLocal(data) {
      this.tieneError = data;
    },

    async callAbandonar() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: estas_seguro,
        componentProps: {
          title: this.t("app.recoverPassForm.tit_abandonar"),
          color_title: "rojo",
          color_btn_1: "rojo",
          color_btn_2: "gris",
          text: "",
          btnText_1: this.t("app.comunes.btns.salir"),
          btnText_2: "",
          colorIcon: "rojoOscuro",
        },
        // cssClass:["myModalAbandonar"],
        cssClass:
          this.widthScreen < 600
            ? [
                "myModalAbandonar",
                "myModalAbandonar_ancho_80",
                "myModalAbandonar_alto_50",
              ]
            : [
                "myModalAbandonar",
                "myModalAbandonar_ancho_40",
                "myModalAbandonar_alto_40",
              ],
        // showBackdrop: false,
        animated: true,
        backdropDismiss: false,
        // swipeToClose: true,
        // mode: "ios",
      });

      modal.onDidDismiss().then((data) => {
        this.updateBloquearNavegacion(false);
        if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_1"
        ) {
          // menuController.close("firstMenu");
          // this.signOff();
          this.$router.push({ name: "logIn", replace: true });
        } else if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_2"
        ) {
          // this.$router.push({ name: "nuevoDestinatarioForm" });
        }
      });
      return modal.present();
    },
  },
  watch: {
    respForgotPass2() {
      // this.mensajeError = "";
      // this.mensajeCodigo = "";
      if (this.respForgotPass2.status && this.enVista) {
        this.setshowSpinner(false);
        if (this.respForgotPass2.resp && this.enVista) {
          console.log(1);
          // this.mensajeCodigo = this.respForgotPass2.msj;
          this.tieneError = false;
          // this.stepRecoverPass = 3;
          this.$router.push({ name: "recoverPassForm", replace: true });
        } else {
          console.log(2);
          this.tieneError = true;
          this.error_respForgotPass2 = "";
          if(this.respForgotPass2.msj == "ERROR_CLI_009"){
            this.error_respForgotPass2 = this.respForgotPass2.msj;
          }
          
          this.presentAlert();
        }
        this.btnActive = true;
        this.procesando_codigo = false;
      }
    },
  },
  mounted() {
    // console.log(this.remitente);
  },
  ionViewDidEnter() {
    this.enVista = true;
  },
  ionViewWillLeave() {
    this.enVista = false;
  },
};
</script>

<style>
.autenticaTitle {
  color: var(--customA-801B1C);
  margin-top: 1.5rem;
  text-align: center;
}

.autenticaText {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 18px;
  color: var(--customG-787878);
  margin-top: 2.3rem;
  text-align: justify;
  text-justify: inter-word;
  padding: 0.2rem;
}

.autenticaTextBold {
  font-weight: 500;
  color: var(--customH-4C4C4C);
}

.autenticaCodigo {
  margin-top: 3.5rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 16.41px;
  color: var(--customA-801B1C);
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.autenticaRPResend {
  margin-top: 3.5rem;
  font-family: "Roboto";
  font-style: normal;
  line-height: 13px;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  display: flex;
  justify-content: center;
  font-size: 0.6875rem;
  font-weight: 700;
  color: var(--customG-787878);
}

.autenticaProblemsText {
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 12.89px;
  color: var(--customH-4C4C4C);
  display: flex;
  justify-content: center;
}

.autenticaProblemsBold {
  font-weight: 700;
  color: var(--customC-614AD3);
}

.customBtnClassAutentica {
  font-size: 0.5rem;
  font-weight: 500;
  line-height: 9.38px;
  color: var(--customH-4C4C4C) !important;
}

.alert-sub-title {
  font-size: 0.5rem;
  font-weight: 700;
  line-height: 18.38px;
  color: var(--customC-614AD3) !important;
  /* border: 1px solid green; */
}

.toastAutenticationRecoverPass {
  width: 80%;
  margin-left: 10%;
  margin-top: 3rem;
  text-align: center;
  /* background: var(--customG-787878); */
  /* color: var(--customC-614AD3); */
}

:host.toast-container {
  background: var(--customG-787878) !important;
  color: white !important;
}

.toast-container {
  background: var(--customG-787878) !important;
  color: white !important;
}

.toast-wrapper {
  background: var(--customG-787878) !important;
}

.autenticaRPResendedCod {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.6875rem;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  margin-top: 3.5rem;
  color: var(--customC-614AD3);
}

.autenticaRPEtapas {
  margin-top: 9rem;
}
</style>